.root {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
}

.shortContainer {
  width: 40px;
  height: 40px;
  background-color: #3b4149;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1;
  cursor: pointer;
}

.fullContainer {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  max-width: 350px;
  background-color: var(--mantine-color-gray-7);
  height: 100%;
}
